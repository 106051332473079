'use client';
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorageState } from '@/modules/localStorage';
import { getUser } from '@/modules/apiClient';
import { User, Customer } from '@/modules/apiTypes';

interface UserContextProps {
  authToken: string;
  userInfo: User | undefined;
  selectedCustomer: Customer | undefined;
  setSelectedCustomer: Dispatch<Customer>;
  setAuthToken: Dispatch<string>;
  logOut(): void;
  updateUserInfo(): void;
  userLoginAlert: boolean;
  setUserLoginAlert: Dispatch<boolean>;
  userFetched: boolean;
}

export const UserContext = createContext<UserContextProps>({
  authToken: '',
  userInfo: undefined,
  selectedCustomer: undefined,
  setSelectedCustomer: () => null,
  setAuthToken: () => null,
  logOut: () => null,
  updateUserInfo: () => null,
  userLoginAlert: false,
  setUserLoginAlert: () => null,
  userFetched: false,
});

export const useUserContext = () => useContext(UserContext);

export const USER_AUTH_TOKEN_KEY = 'token';

export function UserAuthContext({ children }: { children: ReactNode }) {
  const [authToken, setAuthToken, authTokenInitialized] = useLocalStorageState(
    USER_AUTH_TOKEN_KEY,
    ''
  );

  const [userInfo, setUserInfo] = useState<User>();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [userLoginAlert, setUserLoginAlert] = useState<boolean>(false);
  const [userFetched, setUserFetched] = useState(false);

  const updateUserInfo = useCallback(async () => {
    if (authToken) {
      try {
        const fetchedUser = await getUser(authToken);
        setUserInfo(fetchedUser);
        if (selectedCustomer === undefined && fetchedUser !== undefined) {
          setSelectedCustomer(fetchedUser.customers?.[0]);
        }
      } finally {
        setUserFetched(true);
      }
    }
  }, [authToken, selectedCustomer]);

  useEffect(() => {
    if (!!authToken && authTokenInitialized) {
      void updateUserInfo();
    } else if (!authToken && authTokenInitialized) {
      setUserFetched(true);
    }
  }, [authToken, authTokenInitialized, updateUserInfo]);

  useEffect(() => {
    if (userLoginAlert) {
      const timeout = setTimeout(() => {
        setUserLoginAlert(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [userLoginAlert]);

  const logOut = useCallback(() => {
    setAuthToken('');
    setUserInfo(undefined);
    setSelectedCustomer(undefined);
    setUserFetched(false);
  }, [setAuthToken, setUserInfo, setSelectedCustomer]);

  const value = useMemo(() => {
    return {
      authToken,
      userInfo,
      selectedCustomer,
      setSelectedCustomer,
      setAuthToken,
      logOut,
      updateUserInfo,
      userLoginAlert,
      setUserLoginAlert,
      userFetched,
    };
  }, [
    authToken,
    userInfo,
    selectedCustomer,
    setAuthToken,
    logOut,
    updateUserInfo,
    userLoginAlert,
    userFetched,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
